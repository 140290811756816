<template>
    <div class="rates__item" :class="{'rates__item--active':rate.is_current}">
        <div class="rates__sticky-header">
            <div class="rates__header">
                <h2 class="rates__title">{{ rate.name }}</h2>
            </div>
            <button
                class="rates__btn"
                :class="{'rates__btn--selected': rate.is_current, 'rates__btn--demo free-trial-margin': isDemo && rate.is_current}"
                @click="checkedRate(rate)"
            >
                <span v-if="rate.is_current">Ваш тариф</span>
                <span v-if="!rate.is_current">Выбрать</span>
<!--                <span v-if="!rate.is_current && rate.access_free_period ">-->
<!--                    Попробовать бесплатно-->
<!--                </span>-->
            </button>
            <span class="rates__btn-descr" v-if="isDemo && rate.is_current">Пробный период истекает {{ paymentDetail.date_to | moment('from', dates ) }}</span>
        </div>
        <ul class="rates__list rates__price" :class="{'no-margin': isDemo && rate.is_current}">
            <li class="rates__list-item">
                <div class="rates__state">
                    <p class="rates__module-name--mobile">Первые 5 пользователей</p>
                    <span class="rates__price-count">
                        {{ rate.price === 0 ? 'Бесплатно' : rate.price }}
                    </span>
                    <span v-if="rate.price > 0" class="rates__per-month">
                        &#8381;/мес
                    </span>
                </div>
            </li>
            <li class="rates__list-item">
                <div class="rates__state" :class="{'rates__state--disabled':rate.user_price === 0}">
                    <p class="rates__module-name--mobile">+1 пользователь</p>
                    <span class="rates__price-count">
                        {{ rate.user_price === 0 ? null : rate.user_price }}
                    </span>
                    <span v-if="rate.user_price > 0" class="rates__per-month">
                        &#8381;/мес
                    </span>
                    <i v-else class="rates__icon"/>
                </div>

            </li>
            <li class="rates__list-item">
                <div class="rates__state" :class="{'rates__state--disabled':rate.user_price === 0}">
                    <p class="rates__module-name--mobile">+1 гигабайт</p>
                    <span class="rates__price-count">
                        {{ rate.size_price === 0 ? null : rate.size_price }}
                    </span>
                    <span v-if="rate.size_price > 0" class="rates__per-month">
                        &#8381;/мес
                    </span>
                    <i v-else class="rates__icon"/>
                </div>
            </li>
        </ul>
        <ul class="rates__list">
            <li class="rates__list-item">
                <p class="rates__module-name--mobile">Пользователи</p>
                <div class="rates__state">{{ rate.type === 1 ? 'До 5' : 'Не ограничено' }}</div>
            </li>
            <li class="rates__list-item" v-for="(module, index) in rate.modules" :key="'module_' + index">
                <p class="rates__module-name--mobile">{{ module.name }}</p>
                <div class="rates__state" v-if="module.is_access !== null" :class="module.is_access ? 'rates__state--enabled' : 'rates__state--disabled'">
                    <i class="rates__icon"/>
                </div>
                <div class="rates__state" v-else>
                    Скоро
                </div>
            </li>
            <li class="rates__list-item">
                <p class="rates__module-name--mobile">Место под файлы</p>
                <div class="rates__state">{{ rate.size_file }}</div>
            </li>
        </ul>
    </div>
</template>

<script>
    import session from '@/api/session';
    import {mapState, mapGetters} from "vuex";

    export default {
        name: "Item",
        props: {
            rate: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                dates: null,
                user_count: 0,
                isSelected: false,
            }
        },
        computed: {
            ...mapState('default_data', [
                'time',
            ]),
            ...mapState('settings', [
                'paymentDetail'
            ]),
            ...mapGetters('settings', [
                'selectedRate'
            ]),
            isDemo() {
                return this.paymentDetail.is_demo && !this.paymentDetail.is_free_rate
            }
        },
        methods: {
            checkedRate(rate) {
                if (!rate.is_current && rate.type === 1 && rate.count < this.user_count) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        title: 'Количество пользователей превышает максимум для выбранного тарифа',
                        icon: "error",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        onOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer);
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                        }
                    });
                } else if (!rate.is_current && rate.type === 1 && this.folder_size > this.size_rate) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        title: 'Облачное хранилище превышает объем для выбранного тарифа',
                        icon: "error",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        onOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer);
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                        }
                    });
                } else if (!rate.is_current) {
                    try {
                        this.$swal({
                            customClass: {
                                confirmButton: 'btn btn-lg btn-alt-success m-5',
                                cancelButton: 'btn btn-lg btn-alt-danger m-5'
                            },
                            title: rate.name,
                            text: "Желаете перейти?",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Да',
                            cancelButtonText: 'Отменить'
                        }).then(async (result) => {
                            if (result.value) {
                                await session.post(`/api/v1/rate/${rate.id}/active/`);
                                window.location.reload();
                            }
                        });
                    } catch (error) {
                        Swal.fire({
                            title: 'Ошибка!',
                            text: 'Не удалось подключить тариф',
                            icon: 'error',
                            timer: 2000
                        }).catch(swal.noop);
                    }
                }
            },
            num2str(n, text_forms) {
                n = Math.abs(n) % 100;
                var n1 = n % 10;

                if (n > 10 && n < 20) {
                    return text_forms[2];
                }

                if (n1 > 1 && n1 < 5) {
                    return text_forms[1];
                }

                if (n1 == 1) {
                    return text_forms[0];
                }

                return text_forms[2];
            },
        },
        created() {
            let date = new Date();
            // date.setDate(date.getDate() + 1);
            this.dates = date.toLocaleString("en-US", {timeZone: this.time.time_zone, hour12: false }).replace(',', '')
            session
                .get('/api/v1/users/count/')
                .then(response => {
                    this.user_count = response.data['count'];
                })
                .catch(() => {
                });
        },
    }
</script>

<style lang="sass" scoped>
    @import "#sass/v-style"

    $enabled-icon: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 0C6.3 0 0 6.3 0 14C0 21.7 6.3 28 14 28C21.7 28 28 21.7 28 14C28 6.3 21.7 0 14 0ZM12.6 20.4L5.1 13.5L6.5 12L12.5 17.5L21.8 6.8L23.4 8.1L12.6 20.4Z' fill='%23FFD80B'/%3E%3C/svg%3E%0A")
    $disabled-icon: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28ZM19.7231 10.247L15.3846 14.5854L19.6308 18.9239C20 19.2931 20 19.9393 19.6308 20.3085C19.4462 20.4931 19.2615 20.5854 18.9846 20.5854C18.8 20.5854 18.5231 20.4931 18.3385 20.3085L14 15.9701L9.56923 20.3085C9.38462 20.4931 9.2 20.5854 8.92308 20.5854C8.73846 20.5854 8.46154 20.4931 8.27692 20.3085C7.90769 19.9393 7.90769 19.2931 8.27692 18.9239L12.6154 14.5854L8.27692 10.247C7.90769 9.87776 7.90769 9.2316 8.27692 8.86237C8.64615 8.49314 9.29231 8.49314 9.66154 8.86237L14 13.2008L18.3385 8.86237C18.7077 8.49314 19.3538 8.49314 19.7231 8.86237C20.0923 9.2316 20.0923 9.87776 19.7231 10.247Z' fill='%23DADADA'/%3E%3C/svg%3E%0A")
    .rates
        &__item
            display: flex
            flex-direction: column
            flex-grow: 1
            border-radius: 10px
            border: 2px solid #E6EBF3
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1)
            transition: all .12s ease-out

            &:hover
                -webkit-transform: translateY(-3px)
                transform: translateY(-3px)

            &:nth-child(n+2)
                margin-left: 20px

            &--active
                border: 2px solid #FBC04F

        &__header
            display: flex
            justify-content: center
            align-items: center
            border-radius: 9px 9px 0 0
            padding: 30px 0

        &__sticky-header
            position: sticky
            top: 0
            background: #FFF
            z-index: 99999
            margin-top: 4px
            max-height: 164px
            display: flex
            flex-direction: column

        &__title
            margin-bottom: 0
            font-size: 1.75rem

        &__list
            padding: 30px
            margin-bottom: 0
            position: relative
            min-height: 194px

            &-item
                display: flex
                justify-content: space-between
                align-items: center
                position: relative
                flex-direction: column
                width: 100%
                height: 24px

                &:nth-child(n+2)
                    margin-top: 20px

                @include below($mobile)
                    height: 70px

            &:first-of-type
                &::before
                    position: absolute
                    content: ''
                    bottom: 0
                    left: 0
                    width: 80%
                    margin-left: 10%
                    margin-right: 10%
                    height: 1px
                    background: #D4E5E8

        &__name
            color: #000
            font-size: 1rem
            flex-basis: 100%

        &__icon
            display: inline-block
            height: 24px
            width: 24px

        &__state
            color: #000000
            width: 100%
            text-align: center
            max-height: 24px

            &--enabled
                display: flex
                justify-content: center
                .rates__icon
                    display: block
                    background-image: $enabled-icon
                    background-repeat: no-repeat
                    background-size: cover

            &--disabled
                display: flex
                justify-content: center
                .rates__icon
                    display: block
                    background-image: $disabled-icon
                    background-repeat: no-repeat
                    background-size: cover

            &--soon
                display: unset

            @include below($mobile)
                flex-direction: column
                justify-content: space-around
                align-items: center
                min-height: 40px

        &__btn
            display: flex
            flex-direction: column
            align-items: center
            max-width: 213px
            margin: 0 auto 30px auto
            padding: 15px 0
            width: 75%
            justify-content: center
            background: #FBC04F
            border: 1px solid #FBC04F
            border-radius: 40px
            font-weight: 600
            font-size: 15px
            line-height: 19,95px
            cursor: pointer
            height: 40px

            &:active
                opacity: 0.8
                color: #000

            &-descr
                font-weight: normal
                text-align: center
                width: 100%
                word-break: word-wrap
                @include below($tablet)
                    margin-left: 10%
                    width: 80%

                @include below($mobile)
                    margin-left: 10%
                    width: 80%

            &--selected
                background: #E6EBF3
                border: 1px solid #E6EBF3

            &--demo
                max-width: 360px

        &__price
            &-count
                font-size: 1rem
                color: #000

                &--large
                    font-size: 1.5rem
                    font-weight: 600

        &__module-name
            &--mobile
                display: none
                @include below($mobile)
                    display: block
                    width: 100%
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: center;
                    color: #989CAE;
                    margin: 0

    @media (max-width: 1500px)
        .rates__name
            width: 55%
        .rates__price
            &-count
                &--large
                    font-size: 1rem

    @media (max-width: 500px)
        .rates__list
            padding: 30px 5px

    .free-trial-margin
        margin-bottom: 10px !important
</style>
