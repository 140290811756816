<template>
    <div class="rates__wrap">
        <button type="button" class="btn-block-option close-modal" @click="$modal.hide('Rates')"> ×
        </button>
        <h2 class="h2">Тарифы</h2>
        <div class="rates">
            <div class="modules">
                <ul>
                    <li class="modules__item">
                        <div>
                            Первые 5 пользователей
                        </div>
                    </li>
                    <li class="modules__item">
                        <div>
                            +1 пользователь
                        </div>
                    </li>
                    <li class="modules__item">
                        <div>
                            +1 гигабайт
                        </div>
                    </li>
                    <li class="modules__item">
                        <div>
                            Пользователи
                        </div>
                    </li>
                    <li v-for="(module) in rates[0].modules" :key="module.name" class="modules__item">
                        <div>
                            {{ module.name }}
                        </div>
                    </li>
                    <li class="modules__item">
                        <div>
                            Место под файлы
                        </div>
                    </li>
                </ul>
            </div>
            <swiper
                :options="swiperOptions"
                v-if="windowSize <= 1000"
            >
                <swiper-slide v-for="(rate) in rates" :key="'rate_' + rate.id">
                    <item :rate="rate"></item>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
            <item v-if="windowSize > 1000" v-for="(rate) in rates" :key="'rate_' + rate.id" :rate="rate"></item>
            <div class="rates__free-state">
                Бесплатно
            </div>
        </div>
    </div>
</template>

<script>
    import Item from "./Item";
    import session from '@/api/session';

    export default {
        name: "Rates",
        components: {
            Item,
        },
        props: {
            rates: {
                type: Array,
                required: true
            },
        },
        data() {
            return {
                is_free_mode: false,
                exclude_domain: false,
                swiperOptions: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                    centeredSlides: true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                windowSize: document.documentElement.clientWidth
            }
        },
        methods: {
            onResize() {
                this.windowSize = document.documentElement.clientWidth;
                if(this.windowSize <= 768) {
                    this.swiperOptions.slidesPerView = 1
                }
            }
        },
        created() {
            session
                .get('/api/v1/rate/current_rate/')
                .then(response => {
                    this.is_free_mode = response.data.is_free_mode;
                    this.exclude_domain = response.data.exclude_domain;
                })
                .catch(() => {
                });
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        }
    }
</script>

<style lang="sass">
    @import "#sass/v-style"
    .rates
        display: flex
        justify-content: space-between
        width: 100%
        position: relative
        &__free-state
            position: absolute
            display: none
            justify-content: center
            align-items: center
            border: 5px solid $gold
            background-color: #FFF
            font-size: 2rem
            text-transform: uppercase
            font-weight: 600
            width: 45%
            height: 25%
            left: 50%
            top: 50%
            transform: translate(-50%, -50%)
            z-index: 10
        &--free
            &::before
                z-index: 5
                position: absolute
                content: ''
                height: 100%
                width: 100%
                background-color: rgba(255, 255, 255, 0.67)
            .rates__free-state
                display: flex

        .swiper-button-prev,
        .swiper-button-next

            @media (max-width: 767px)
                position: fixed
                top: 50%

    .swiper-slide
        display: flex
        justify-content: center

    .modules
        position: relative
        top: 201px

        @media (max-width: 1300px)
            margin-right: 15px

        &__item
            white-space: nowrap
            line-height: 1.5
            font-weight: 400
            font-size: 16px
            font-style: normal
            color: #000
            margin-top: 20px

            &:first-child
                margin-top: -1px !important

            &:nth-child(4)
                margin-top: 81px !important

        @include below($mobile)
            display: none

    @media (max-width: 600px)
        .rates__free-state
            width: 95%
            height: 20%
            font-size: 1.5rem

    @include below($mobile)
        .swiper-slide
            width: 100% !important

</style>
<style lang="scss">
    .v--modal-overlay[data-modal="Rates"] {
        .v--modal-box {
            padding: 30px;
            max-width: 1180px !important;
            margin: 0 auto;
            overflow: visible;
        }
    }
</style>
